import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../router/router";
import "../styles/pages/Page.scss";

const SteamConnectPage = () => {
    const isLoggedIn = useSelector((state: any) => state.reducer.isLoggedIn);
    const navigate = useNavigate();
    const [url, setUrl] = useState("");

    const checkIfNotLoggedIn = () => {
        if (!isLoggedIn) {
            navigate(paths.register);
        }
    };

    const setUrlByWebsite = (response: any) => {
        const host = document.location.host.replace(/^www\./, "");

	const url = response.data.filter((item: any) => item.website_domain === host)[0].url.split("/").filter((part: string) => part)
	const connectUrl = url[0] + "//" + url[1]

        setUrl(
            connectUrl
        );
    };

    const getUrl = async () => {
        await axios
            .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/panel/`)
            .then((res) => setUrlByWebsite(res));
    };

    useEffect(() => {
        checkIfNotLoggedIn();
        getUrl();
    }, []);

    return (
        <div className="page">
            {url && (
                <iframe
                    src={url}
                    style={{ border: "0", width: "100%", height: "100%" }}
                ></iframe>
            )}
        </div>
    );
};

export default SteamConnectPage;
