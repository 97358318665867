import pudgeMania from "../sources/PudgeMania.png";
import tournamentBg2 from "../sources/duels_bg.png";
import tournamentBg from "../sources/tournament_bg.jpg";

export const tops = [
    {
        id: 1,
        game: "cs2",
        game_name: "CS 2",
        cover_image:
            "https://cdn.akamai.steamstatic.com/apps/csgo/images/csgo_react/social/cs2.jpg",
        name: "ESL CS2 5v5 Competitive #39",
        prizes: "Steam Cards",
    },
    {
        id: 2,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournamentBg,
        name: "ESL CS2 2v2 Duels #15",
        prizes: "Steam Cards",
    },
    {
        id: 3,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournamentBg2,
        name: "ESL CS2 2v2 Grand Competitive",
        prizes: "CS 2 Skins",
    },
    {
        id: 1,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image:
            "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota2_social.jpg",
        name: "ESL Dota 2 Premium Duel SKY #4",
        prizes: "Steam Cards",
    },
    {
        id: 2,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image:
            "https://img.ixbt.site/live/images/original/15/26/60/2023/04/21/9c6a5b89c9.png?w=877",
        name: "ESL Dota 2 February Tournament",
        prizes: "$ USD",
    },
    {
        id: 3,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image: pudgeMania,
        name: "ESL Dota 2 Pudge Mania",
        prizes: "Dota 2 Skins",
    },
];
