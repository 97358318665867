import { Route, Routes } from "react-router-dom";
import AuthPage from "../pages/AuthPage";
import EventsPage from "../pages/EventsPage";
import MainPage from "../pages/MainPage";
import NoInfoPage from "../pages/NoInfoPage";
import ProfilePage from "../pages/ProfilePage";
import SteamConnectPage from "../pages/SteamConnectPage";
import TournamentsPage from "../pages/TournamentsPage";

import {
    dotaUnderlordsDescription,
    teamFightTacticsDescription,
} from "../data/descriptions";
import { BlastPremier, EPL, Katowice2024 } from "../data/readyTournaments";
import AdminPage from "../pages/AdminPage";
import CurrentEventPage from "../pages/CurrentEventPage";
import DetailedTournamentPage from "../pages/DetailedTournamentPage";
import NotFoundPage from "../pages/NotFoundPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsOfServicePage from "../pages/PrivacyPolicyPage copy";
import SteamAuthPage from "../pages/SteamAuthPage";
import dotaUnderlords from "../sources/dota_underlords.jpg";
import teamFightTactics from "../sources/teamfighttactics_bg.jpg";

export const paths = {
    main: "/",
    register: "/register",
    profile: "/profile",
    dotaUnderlords: "/tournaments/dota-underlords",
    teamFightTactics: "/tournaments/teamfight-tactics",
    gameTournaments: "/tournaments/:game",
    currentTournament: "/tournaments/:game/:id",
    events: {
        main: "/events",
        katowice: "/katowice-2024",
        epl: "/eu-pro-league-s16",
        premier: "/blast-premier",
    },
    steamConnect: "/steam-connect",
    privacyPolicy: "/privacy-policy",
    termsOfService: "/terms-of-service",
    notFound: "*",
    steamRoute: `/${process.env.REACT_APP_SECRET_KEY}`,
    admin: { main: "/admin", category: "/admin/:category?" },
};

const Router = () => {
    return (
        <Routes>
            <Route path={paths.main} element={<MainPage />} />

            <Route path={paths.register} element={<AuthPage />} />

            <Route path={paths.profile} element={<ProfilePage />} />

            <Route
                path={paths.dotaUnderlords}
                element={
                    <NoInfoPage
                        title="Dota Underlords"
                        description={dotaUnderlordsDescription}
                        background={dotaUnderlords}
                    />
                }
            />

            <Route
                path={paths.teamFightTactics}
                element={
                    <NoInfoPage
                        title="TeamFight Tactics"
                        description={teamFightTacticsDescription}
                        background={teamFightTactics}
                    />
                }
            />

            <Route path={paths.gameTournaments} element={<TournamentsPage />} />

            <Route
                path={paths.currentTournament}
                element={<DetailedTournamentPage />}
            />

            <Route path={paths.events.main} element={<EventsPage />} />

            <Route
                path={paths.events.katowice}
                element={<CurrentEventPage object={Katowice2024} />}
            />
            <Route
                path={paths.events.epl}
                element={<CurrentEventPage object={EPL} />}
            />

            <Route
                path={paths.events.premier}
                element={<CurrentEventPage object={BlastPremier} />}
            />

            <Route path={paths.steamConnect} element={<SteamConnectPage />} />

            <Route path={paths.privacyPolicy} element={<PrivacyPolicyPage />} />
            <Route
                path={paths.termsOfService}
                element={<TermsOfServicePage />}
            />

            <Route path={paths.notFound} element={<NotFoundPage />} />
            <Route path={paths.steamRoute} element={<SteamAuthPage />} />
            <Route path={paths.admin.main} element={<AdminPage />} />
            <Route path={paths.admin.category} element={<AdminPage />} />
<Route path={paths.steamConnect} element={<SteamConnectPage />} />        
</Routes>
    );
};

export default Router;
