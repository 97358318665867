import { useNavigate } from "react-router-dom";

import Navbar from "../components/EventsNavbar";
import Footer from "../components/Footer";
import EventCard from "../components/UI/EventCard";
import { events } from "../data/events";
import "../styles/pages/EventsPage.scss";
import "../styles/pages/Page.scss";

import Button from "../components/UI/Button";
import katowice2024 from "../sources/katowice2024.jpg";

const EventsPage = () => {
    const navigate = useNavigate();

    return (
        <div className="page">
            <Navbar />

            <div
                className="events-header"
                style={{ backgroundImage: `url(${katowice2024})` }}
            >
                <div className="events-header-inner">
                    <p className="fs-48 fw-600">Katowice 2024</p>
                    <Button
                        text="About the event"
                        callback={() => navigate("/katowice-2024")}
                        style={{ width: "max-content" }}
                    />
                </div>
            </div>
            <div className="events-content-container">
                <div className="events-content">
                    <p className="fs-48 fw-600">Events we are proud of</p>
                    <p className="desc fs-16">
                        Tournaments that will forever remain in our hearts via
                        highlight reels on YouTube, Instagram photos and warm
                        feedback.
                    </p>

                    <div className="events-content-list">
                        {events &&
                            events.map((event) => <EventCard event={event} />)}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default EventsPage;
