import { useSelector } from "react-redux";
import AuthForm from "../components/AuthPage/AuthForm";
import Navbar from "../components/AuthPage/AuthNavbar";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../router/router";
import "../styles/pages/Page.scss";

const AuthPage = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: any) => state.reducer.isLoggedIn);

    const redirectOnLoggedIn = () => {
        if (isLoggedIn) {
            navigate(paths.main);
        }
    };

    useEffect(() => {
        redirectOnLoggedIn();
    });

    return (
        <div className="page">
            <Navbar />

            <AuthForm />
        </div>
    );
};

export default AuthPage;
