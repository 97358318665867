import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TournamentsContent from "../components/TournamentsPage/TournamentsContent";
import TournamentsHeader from "../components/TournamentsPage/TournamentsHeader";
import { setGame } from "../redux/actions";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tournaments } from "../data/headTournaments";
import "../styles/pages/Page.scss";

const TournamentsPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const checkIfGameExists = () => {
        if (window.location.href.match(/\/tournaments\/.*$/)) {
            const item = tournaments.some(
                (item: any) => item.game == params.game
            );

            if (!item) {
                navigate("/404");
            } else {
                const game = params.game;

                dispatch(setGame(game));
            }
        }
    };

    useEffect(() => {
        checkIfGameExists();
    }, [params.game]);

    return (
        <div className="page">
            <Navbar />

            <TournamentsHeader />

            <TournamentsContent />

            <Footer />
        </div>
    );
};

export default TournamentsPage;
