import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DTPContent from "../components/DetailedTournamentPage/DTPContent";
import { tournaments } from "../data/headTournaments";
import { setTournament } from "../redux/actions";
import "../styles/pages/DetailedTournamentPage.scss";
import "../styles/pages/Page.scss";

const DetailedTournamentPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const tournament = useSelector(
        (state: any) => state.gameReducer.tournament
    );

    const game = useSelector((state: any) => state.gameReducer.game);

    const checkIfTournamentExists = () => {
        if (window.location.href.match(/\/tournaments\/[^/]+\/[^/]+/)) {
            const item = tournaments.some((item: any) => {
                return item.id == params.id && item.game == game;
            });

            if (!item) {
                navigate("/404");
            } else {
                const game = tournaments.filter(
                    (item: any) =>
                        item.game == params.game && item.id == params.id
                )[0];

                dispatch(setTournament(game));
            }
        }
    };

    useEffect(() => {
        checkIfTournamentExists();
    }, [params.id]);

    return (
        <div className="page">
            <Navbar />

            <div
                className="detailed-tournament-header"
                style={{
                    backgroundImage: `url(${
                        tournament && tournament.cover_image
                    })`,
                }}
            >
                <div className="detailed-tournament-inner">
                    <p className="fs-32 fw-600 c-white">
                        {tournament && tournament.name}
                    </p>
                </div>
            </div>

            <DTPContent />

            <Footer />
        </div>
    );
};

export default DetailedTournamentPage;
