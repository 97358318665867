import { combineReducers, createStore } from "redux";
import gameReducer from "./gameReducer";
import reducer from "./reducer";

const rootReducer = combineReducers({
    reducer: reducer,
    gameReducer: gameReducer,
});

const store = createStore(rootReducer);

export default store;
