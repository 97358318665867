import B8 from "../sources/B8.png";

import BB from "../sources/BB.png";
import Cloud9 from "../sources/Cloud9.png";
import EternalFire from "../sources/Eternal_fire.png";
import RebelsGaming from "../sources/RebelsGaming.png";

import Astralis from "../sources/Astralist.png";
import BIG from "../sources/BIG.png";
import Ence from "../sources/Ence.png";
import Heroic from "../sources/Heroic.png";

import Timer from "../sources/timer.png";

import Intel from "../sources/Intel.png";

import Klee from "../sources/Klee.png";
import LevelUp from "../sources/LevelUp.png";
import MB from "../sources/MB.png";
import NaviJ from "../sources/NaviJ.png";

import Complexity from "../sources/Complexity.png";
import FaZe from "../sources/FaZe.png";
import Navi from "../sources/Navi.png";
import TeamVitaly from "../sources/Vitaly.png";

import Epos from "../sources/Epos.png";
import Unikrn from "../sources/Unikrn.png";
import csMoney from "../sources/csMoney.png";
import Maersk from "../sources/maersk.png";

export const tournamentsKatowice = [
    {
        date: "31 January",
        tournaments: [
            {
                time: "17:53",
                teams: [
                    {
                        image: Cloud9,
                        name: "C9",
                        score: 0,
                        winner: false,
                    },
                    {
                        image: RebelsGaming,
                        name: "REBELS",
                        score: 1,
                        winner: true,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
            {
                time: "16:34",
                teams: [
                    {
                        image: EternalFire,
                        name: "Eternal Fire",
                        score: 0,
                        winner: true,
                    },
                    {
                        image: BB,
                        name: "BB",
                        score: 0,
                        winner: false,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
            {
                time: "21:00",
                teams: [
                    {
                        image: RebelsGaming,
                        name: "REBELS",
                        score: 1,
                        winner: true,
                    },
                    {
                        image: EternalFire,
                        name: "Eternal Fire",
                        score: 0,
                        winner: false,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
        ],
    },
    {
        date: "31 January",
        tournaments: [
            {
                time: "16:30",
                teams: [
                    {
                        image: Heroic,
                        name: "Heroic",
                        score: 1,
                        winner: true,
                    },
                    {
                        image: Astralis,
                        name: "Astralis",
                        score: 0,
                        winner: false,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
            {
                time: "13:00",
                teams: [
                    {
                        image: Ence,
                        name: "ENCE",
                        score: 0,
                        winner: false,
                    },
                    {
                        image: BIG,
                        name: "BIG",
                        score: 1,
                        winner: true,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
            {
                time: "17:40",
                teams: [
                    {
                        image: Heroic,
                        name: "Heroic",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: BIG,
                        name: "BIG",
                        score: 1,
                        winner: false,
                    },
                ],
                location: "IEM Katowice 2024 • Initial Rounds",
            },
        ],
    },
    {
        date: "-",
        tournaments: [
            {
                time: "--:--",
                teams: [
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                ],
                location: "IEM Katowice 2024 • Winners Rounds",
            },
        ],
    },
];

export const shipsKatowice2024 = [
    {
        type: "Sponsor",
        image: Intel,
        link: "https://intelextrememasters.com/",
    },
];

export const tournamentsEPL = [
    {
        date: "16-18 January",
        tournaments: [
            {
                time: "14:00",
                teams: [
                    {
                        image: NaviJ,
                        name: "Navi Junior",
                        score: 1,
                        winner: false,
                    },
                    {
                        image: Klee,
                        name: "Team Klee",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "20:20",
                teams: [
                    {
                        image: NaviJ,
                        name: "Navi Junior",
                        score: 0,
                        winner: false,
                    },
                    {
                        image: MB,
                        name: "MarsBEt Team",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "--:--",
                teams: [
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
        ],
    },
    {
        date: "19-21 January (2 February)",
        tournaments: [
            {
                time: "16:00",
                teams: [
                    {
                        image: B8,
                        name: "B8",
                        score: 0,
                        winner: false,
                    },
                    {
                        image: NaviJ,
                        name: "Navi Junior",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "18:00",
                teams: [
                    {
                        image: LevelUp,
                        name: "Level Up",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: NaviJ,
                        name: "Navi Junior",
                        score: 1,
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "--:--",
                teams: [
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
        ],
    },
    {
        date: "-",
        tournaments: [
            {
                time: "--:--",
                teams: [
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                    {
                        image: Timer,
                        name: "--TDB--",
                        score: "-",
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Winners Rounds",
            },
        ],
    },
];

export const tournamentsBlastPremier = [
    {
        date: "22 November - 25 November",
        tournaments: [
            {
                time: "19:05",
                teams: [
                    {
                        image: Complexity,
                        name: "Complexity",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: Navi,
                        name: "Navi",
                        score: 1,
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "16:58",
                teams: [
                    {
                        image: Cloud9,
                        name: "C9",
                        score: 1,
                        winner: false,
                    },
                    {
                        image: FaZe,
                        name: "FaZe",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "20:42",
                teams: [
                    {
                        image: FaZe,
                        name: "FaZe",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: Complexity,
                        name: "Complexity",
                        score: 1,
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
        ],
    },
    {
        date: "22 November - 25 November",
        tournaments: [
            {
                time: "16:25",
                teams: [
                    {
                        image: Cloud9,
                        name: "C9",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: Heroic,
                        name: "Heroic",
                        score: 0,
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "18:33",
                teams: [
                    {
                        image: TeamVitaly,
                        name: "Team Vitaly",
                        score: 2,
                        winner: true,
                    },
                    {
                        image: Heroic,
                        name: "Heroicr",
                        score: 0,
                        winner: false,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
            {
                time: "16:20",
                teams: [
                    {
                        image: Cloud9,
                        name: "C9",
                        score: 1,
                        winner: false,
                    },
                    {
                        image: TeamVitaly,
                        name: "Team Vitaly",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Initial Rounds",
            },
        ],
    },
    {
        date: "26 November",
        tournaments: [
            {
                time: "18:00",
                teams: [
                    {
                        image: FaZe,
                        name: "FaZe",
                        score: 0,
                        winner: false,
                    },
                    {
                        image: TeamVitaly,
                        name: "Team Vitaly",
                        score: 2,
                        winner: true,
                    },
                ],
                location: "EPL 16 Season • Winners Rounds",
            },
        ],
    },
];

export const shipsBlastPremier = [
    {
        type: "Sponsor",
        image: Maersk,
        link: "https://www.maersk.com/",
    },
    {
        type: "Sponsor",
        image: csMoney,
        link: "https://cs.money/",
    },
    {
        type: "Sponsor",
        image: Epos,
        link: "https://www.eposaudio.com/",
    },
    {
        type: "Sponsor",
        image: Unikrn,
        link: "https://unikrn.com/",
    },
];
