import { useNavigate, useParams } from "react-router-dom";

import "../../styles/components/UI/TournamentInfoCard.scss";

type TournamentInfoCardProps = {
    tournament: any;
};

const TournamentInfoCard = ({ tournament }: TournamentInfoCardProps) => {
    const navigate = useNavigate();
    const params = useParams();

    return (
        <tr
            onClick={() =>
                navigate(`/tournaments/${params.game}/${tournament.id}`)
            }
            className="tournament-content"
        >
            <td className="tournament-name-cell">
                <div
                    style={{
                        backgroundImage: `url(${tournament.cover_image})`,
                    }}
                    className="tournament-icon"
                ></div>
                <p className="tournament-name">{tournament.name}</p>
            </td>
            <td>
                <div className="tournament-status">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <circle
                            cx="3"
                            cy="3"
                            r="3"
                            fill={
                                tournament.date === "Outdated"
                                    ? "#FF007E"
                                    : tournament.date === "Started"
                                    ? "#99FF50"
                                    : "#FFAB2E"
                            }
                        />
                    </svg>
                    {tournament.date}
                </div>
            </td>
            <td>{tournament.mode}</td>
            <td>
                {tournament.access === true ? (
                    <svg
                        className="tournament-access-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        viewBox="0 0 14 15"
                        fill="none"
                    >
                        <path
                            d="M1 12.9376V6.57086C1 6.13132 1.35631 5.77502 1.79584 5.77502H9.75423C10.1938 5.77502 10.5501 6.13132 10.5501 6.57086V12.9376C10.5501 13.3771 10.1938 13.7334 9.75423 13.7334H1.79584C1.35631 13.7334 1 13.3771 1 12.9376Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M5.77527 11.3459C6.65433 11.3459 7.36695 10.6333 7.36695 9.75422C7.36695 8.87516 6.65433 8.16254 5.77527 8.16254C4.89621 8.16254 4.18359 8.87516 4.18359 9.75422C4.18359 10.6333 4.89621 11.3459 5.77527 11.3459Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M8.95898 5.77504V2.9896C8.95898 1.89077 9.84977 1 10.9486 1C12.0474 1 12.9382 1.89077 12.9382 2.9896V5.77504"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        className="tournament-access-icon locked"
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                    >
                        <path
                            d="M1 14.1667V7.50002C1 7.03977 1.3731 6.66669 1.83333 6.66669H10.1667C10.6269 6.66669 11 7.03977 11 7.50002V14.1667C11 14.6269 10.6269 15 10.1667 15H1.83333C1.3731 15 1 14.6269 1 14.1667Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M5.99967 12.5C6.92015 12.5 7.66634 11.7538 7.66634 10.8334C7.66634 9.91288 6.92015 9.16669 5.99967 9.16669C5.0792 9.16669 4.33301 9.91288 4.33301 10.8334C4.33301 11.7538 5.0792 12.5 5.99967 12.5Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.66699 6.66669V5.00002C2.66699 3.15907 4.15938 1.66669 6.00033 1.66669C7.84124 1.66669 9.33366 3.15907 9.33366 5.00002V6.66669H2.66699Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </td>
            <td>
                {tournament.participants_users.length}/{tournament.participants}
            </td>
            <td>{tournament.prizes}</td>
        </tr>
    );
};

export default TournamentInfoCard;
