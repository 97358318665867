import office from "../sources/cs_office.png";
import ancient from "../sources/de_ancient.png";
import anubis from "../sources/de_anubis.png";
import inferno from "../sources/de_inferno.png";
import lunacy from "../sources/de_lunacy.png";
import mirage from "../sources/de_mirage.png";
import nuke from "../sources/de_nuke.png";
import overpass from "../sources/de_overpass.png";
import sirocco from "../sources/sirocco.png";

export const maps = {
    nuke: {
        name: "de_nuke",
        image: nuke,
    },
    inferno: {
        name: "de_inferno",
        image: inferno,
    },
    mirage: {
        name: "de_mirage",
        image: mirage,
    },
    office: {
        name: "cs_office",
        image: office,
    },
    anubis: {
        name: "de_anubis",
        image: anubis,
    },
    lunacy: {
        name: "Lunacy",
        image: lunacy,
    },
    sirocco: {
        name: "Sirocco",
        image: sirocco,
    },
    ancient: {
        name: "de_ancient",
        image: ancient,
    },
    overpass: {
        name: "de_overpass",
        image: overpass,
    },
};
