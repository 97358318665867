import { useSelector } from "react-redux";
import "../../styles/components/DetailedTournamentPage/DTPContent.scss";
import DTPInfoCard from "../UI/DTPInfoCard";
import SettingsTable from "../UI/SettingsTable";

const DTPContent = () => {
    const tournament = useSelector(
        (state: any) => state.gameReducer.tournament
    );

    return (
        <>
            {tournament && (
                <div className="detailed-tournament-content">
                    <DTPInfoCard />

                    <div className="detailed-tournament-content-inner">
                        <div className="detailed-tournament-content-wrapper">
                            <div className="detailed-tournament-settings-table-wrapper">
                                <p className="fs-32 fw-600">
                                    About the tournament
                                </p>

                                <SettingsTable />
                            </div>

                            <div className="detailed-tournament-allowed-wrapper">
                                <p className="fs-32 fw-600">Map pool</p>

                                <div className="detailed-tournament-allowed">
                                    {tournament.map_pool &&
                                        tournament.map_pool.map(
                                            (thing: any) => (
                                                <div className="detailed-tournament-allowed-thing">
                                                    <div
                                                        className="detailed-tournament-allowed-thing-image"
                                                        style={{
                                                            backgroundImage: `url(${thing.image})`,
                                                        }}
                                                    ></div>

                                                    <p>{thing.name}</p>
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detailed-tournament-description-wrapper">
                        <div className="detailed-tournament-description">
                            <p className="fs-32 fw-600">Description</p>

                            <p className="desc fs-16">Prizes:</p>
                            {tournament.description &&
                                tournament.description?.prizes.map(
                                    (prize: any) => (
                                        <p className="desc fs-16">{prize}</p>
                                    )
                                )}
                        </div>
                    </div>

                    <div className="detailed-tournament-participants-wrapper">
                        <div className="detailed-tournament-participants">
                            {tournament.participants_users.length > 0 && (
                                <p className="fs-32 fw-600">Participants</p>
                            )}

                            {tournament.participants_users.length > 0 &&
                                tournament.participants_users.map(
                                    (user: any) => (
                                        <div className="detailed-tournament-participant">
                                            <div
                                                className="detailed-tournament-participant-avatar"
                                                style={{
                                                    backgroundImage: `url(${user.image})`,
                                                }}
                                            ></div>

                                            <p className="fs-16 fw-600">
                                                {user.name}
                                            </p>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DTPContent;
