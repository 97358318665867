import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../router/router";
import { localStorageController } from "../utils/localStorageController";

const SteamAuthPage = () => {
    const isLoggedIn = useSelector((state: any) => state.reducer.isLoggedIn);
    const navigate = useNavigate();

    const checkUser = async () => {
        try {
            const token = await localStorageController.getAccessToken();

            const headers: any = {
                Authorization: `Bearer ${await token}`,
            };

            await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/auth/set_steam_connected/`,
                {},
                { headers }
            );
        } catch {
        } finally {
            return navigate(paths.profile);
        }
    };

    useEffect(() => {
        checkUser();
    }, []);

    return <div></div>;
};

export default SteamAuthPage;
