import TournamentInfoCard from "./TournamentInfoCard";

import { tournaments as games } from "../../data/headTournaments";

import { useSelector } from "react-redux";
import "../../styles/components/UI/TournamentInfo.scss";

const TournamentInfo = () => {
    const currentGame = useSelector((state: any) => state.gameReducer.game);
    const tournaments = games.filter((item) => item.game == currentGame);

    return (
        <table className="tournament-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Mode</th>
                    <th>Access</th>
                    <th>Participants</th>
                    <th>Prizes</th>
                </tr>
            </thead>
            <tbody>
                {tournaments.length > 0 &&
                    tournaments.map((tournament: any) => (
                        <TournamentInfoCard tournament={tournament} />
                    ))}
            </tbody>
        </table>
    );
};

export default TournamentInfo;
