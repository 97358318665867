export const dotaUnderlordsDescription = `The popularity of various Autobattlers is indisputable right now, and Dota Underlords has become by far one of the major titles of the genre. It’s an official take on the Autobattler formula by Valve, and the esports potential of the game is already attracting thousands of professional players willing to compete on a global scale in Dota Underlords tournaments.
Moreover, common players are also keen on the idea of participating in various tournaments, but it’s not always easy to pave your way towards huge events. And while the skill gap between the professional players and amateurs might be too big for them to perform on the same level, there’s still a chance for common players to play competitively. Thanks to the thorough tournament platform presented by ESL, all of you out there have the opportunity to participate in our events dedicated to amateur players. And yes, we offer prize pools – so that you feel motivated to win. But, perhaps, the most valuable impact of out tournaments is the much-needed experience, which will later help you become a professional esports athlete.
That said, we host a variety of online Dota Underlords tournaments – not only the ones on a weekly basis with relatively small prize pools but also huge events with proper rewards. Regardless of what type of events you choose to start with, we can guarantee that you’ll have a great time.
`;

export const cs2Description = `
CS2 is among the most popular and well-known multiplayer shooters in the world. Millions of people turn on their computer to play CS2 every day, to break into the world of battles, adrenaline and experience the sense of victory over the enemy. This is not just a game. CS2 tournaments are part of the esports world, which has long occupied his niche among other types of entertainment.
Once there were non-professional tournaments for beginners and amateurs, but today esports is a brand new and fully completed show. Now we have big tournaments including international ones and the competitions that the whole world is watching. It is not surprising that many people want to get into this sport and become a part of history, participate in tournaments that aggregate huge stadiums. Not everyone knows where to start, but there is always a solution. 
How to become a pro player? Easy-peasy! You just need to sharpen your skills. To do so, you may start with something little: enter CS2 tournaments for beginners, for example. You don't need to have a full team of five players at the start, as your individual skill is what matters at the beginning of your esports path.
In addition to the usual practice in matchmaking, sometimes you have to try yourself in competitive matches. These are completely different emotions, and also the ability to get rewards changes the approach to the game.
The most popular are online tournaments, and we have a special platform to hold them. ESL Esports team have created a series of weekly CS2 tournaments for all those who wish to join the world of Counter-Strike, including beginners. Here you can compete with other players in any convenient format, as well as make new acquaintances, discuss the problems of CS2 and even find a team.
Free tournaments for beginners is a great way to acquire skills at playing Counter-Strike, as well as prepare for competitions with stronger opponents.
`;

export const dota2Description = `
Dota 2 has reached stellar heights of popularity in the course of recent years. Being an esports-oriented title, the game offers exciting opportunities for many world-famous teams to perform on the global arena via a series of official Dota 2 tournaments called Dota Pro Circuit.
In the highly competitive ecosystem of Dota 2 tournaments, it’s fairly hard for an unprepared player to do something to be noticed. The esports scene is occupied by well-known organizations and clans, and even if you happen to be far from the overall idea of Dota 2 tournaments, you’ve definitely heard about the famous teams. But what can be done to enter this engaging, yet overcrowded world of esports? Well, everyone has to start somewhere.
First, you need to make sure that you are skilled enough to perform at a reasonably high level. You need to train more so that you can adjust your strategies on the go in accordance with the unfolding events during the matches. You obviously need a team of friends – and your crew doesn’t have to be a professional one. Simply learn the game and improve your team play – that’s a great start to advance for online Dota 2 tournaments for beginners.
ESL Esports team has established an easy-to-use tournament platform so that you can make the first steps towards the professional competitive Dota 2 stage. It’s completely free, and you can jump in regardless of your current level of plays. Good luck on your way to the top!
`;

export const teamFightTacticsDescription = `Autobattlers had burst into our lives swiftly and vividly, but contrary to the saying they managed to gain a foothold in our hearts. Riot Games’ Teamfight Tactics is a project to keep an eye on — a part of the League of Legends universe and a unique autobattler, which differs from its competitors in high dynamics, delightful graphics, and innovative mechanics. Moreover, spending time playing your favorite game can be not only pleasant but also useful at the same time — it doesn’t matter at all if this is an endless struggle for the highest rank or just attempts to sharpen personal skills.
We introduce you to the Teamfight Tactics Tournament Platform! Now you can play TFT not only for the soul but also earn valuable prizes in online tournaments organized by ESL Moving forward step by step will help not only to level-up personally but also bring unforgettable emotions. And, of course, you’ll get rewards for all the efforts made in the battles.
How to become a top-player at Teamfight Tactics, develop your autobattler skills, and achieve the highest rank in TFT? Only by fighting against valid opponents, motivated and strong ones. There is no difference if you set yourself a goal to achieve the professional level or just play Teamfight Tactics because your love for this game is boundless (although one does not contradict the other at all) — on our tournament platform fans with the most diverse game skills can step into battle not only to get valuable prizes but also for the infinitely valuable experience of TFT online tournaments.
Anyone can take part in our Teamfight Tactics online tournaments, you just need to register and choose one of the proposed options. ESL is organizing TFT online competitions as frequently as possible, as well as ESL plans to hold major Teamfight Tactics events, where prize pools will rise together with the number of contestants.`;
