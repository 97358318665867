import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/pages/NotFoundPage.scss";
import "../styles/pages/Page.scss";

const NotFoundPage = () => {
    return (
        <div className="page">
            <Navbar />

            <div className="not-found-page-container">
                <p className="fs-76 fw-600">404</p>
                <p className="fs-56 fw-600">Not Found</p>
                <p className="fs-16">{"Here is no esports on this page :>"}</p>
            </div>

            <Footer />
        </div>
    );
};

export default NotFoundPage;
