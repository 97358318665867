export const setIsLoggedIn = (value: unknown) => ({
    type: "SET_IS_LOGGED_IN",
    payload: value,
});

export const setAdminIsLoggedIn = (value: unknown) => ({
    type: "SET_ADMIN_IS_LOGGED_IN",
    payload: value,
});

export const setGame = (value: unknown) => ({
    type: "SET_GAME",
    payload: value,
});

export const setTournament = (value: unknown) => ({
    type: "SET_TOURNAMENT",
    payload: value,
});
