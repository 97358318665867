import dangerZoneBg from "../sources/DangerZone.png";
import pudgeMania from "../sources/PudgeMania.png";
import competitive5v5 from "../sources/competitive_bg.png";
import tournamentBg2 from "../sources/duels_bg.png";
import lanBg from "../sources/lan_bg.png";
import timer from "../sources/timer.png";
import tournamentBg from "../sources/tournament_bg.jpg";
import zeroGravityBg from "../sources/zero_gravity_bg.png";

import tournament_10 from "../sources/10.png";
import tournament_11 from "../sources/11.png";
import tournament_14 from "../sources/14.png";
import tournament_15 from "../sources/15.png";
import tournament_16 from "../sources/16.png";
import tournament_17 from "../sources/17.png";
import tournament_18 from "../sources/18.png";
import tournament_19 from "../sources/19.png";
import tournament_20 from "../sources/20.png";
import tournament_21 from "../sources/21.png";
import tournament_22 from "../sources/22.png";
import tournament_23 from "../sources/23.png";
import tournament_24 from "../sources/24.png";
import tournament_25 from "../sources/25.png";
import tournament_26 from "../sources/26.png";
import tournament_27 from "../sources/27.png";
import tournament_28 from "../sources/28.png";
import tournament_29 from "../sources/29.png";
import tournament_30 from "../sources/30.png";
import tournament_31 from "../sources/31.png";
import tournament_8 from "../sources/8.png";
import tournament_9 from "../sources/9.png";
import { maps } from "./maps";

import dota_4 from "../sources/dota_4.png";
import dota_5 from "../sources/dota_5.png";
import dota_6 from "../sources/dota_6.png";

import Ardour from "../sources/Ardour.png";
import BleedEs from "../sources/Bleed_esports.png";
import Execration from "../sources/Execration.png";
import geekFam from "../sources/GeekFam.png";
import MAGI from "../sources/MAGI.png";
import MAGN from "../sources/MAGN.png";
import MIPH from "../sources/MIPH.png";
import MeParb from "../sources/MeParb.png";
import PoEs from "../sources/Polaris_esports.png";
import TalonEs from "../sources/Talon.png";
import TeamTough from "../sources/TeamTough.png";
import TT from "../sources/Team_Trust.png";
import YG from "../sources/Yangon_Galacticos.png";
import TeamDarleng from "../sources/darleng.png";
import IHC from "../sources/ihc.png";
import TeamFreedom from "../sources/team_freedom.png";

export const tournaments = [
    {
        id: 1,
        game: "cs2",
        game_name: "CS 2",
        cover_image:
            "https://cdn.akamai.steamstatic.com/apps/csgo/images/csgo_react/social/cs2.jpg",
        name: "ESL CS2 5v5 Competitive #39",
        date: "Upcoming",
        mode: "5v5 Single elimination",
        shortMode: "5v5",
        access: true,
        participants: "16",
        prizes: "Steam cards",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "15",
        bracket_logic: "Follow by",
        map_pool: [
            {
                name: "de_vertigo",
                image: "https://cyber-sport.io/imgs/news_354/20220828/1661696846_db6185bf973ce0d34e39.jpg",
            },
            {
                name: "de_dust2",
                image: "https://img.redbull.com/images/c_limit,w_1500,h_1000,f_auto,q_auto/redbullcom/2020/7/15/mdlhtjaz85gjhahyakce/csgo-dust-2-map",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - $150 Steam card", "2 place - $100 Steam card"],
        },
        participants_users: [
            {
                image: "https://virtus-img.cdnvideo.ru/images/details-logo/plain/32/32d981ec-3ae6-4be0-b3bd-9eb8fc8f5c48.png",
                name: "x-kom Team",
            },
            {
                image: "https://virtus-img.cdnvideo.ru/images/details-logo/plain/1b/1bd14af5-d670-4b1c-811f-be466279879a.jpg",
                name: "XPC Red",
            },
            {
                image: "https://virtus-img.cdnvideo.ru/images/details-logo/plain/a6/a654960e-ffe4-4953-98df-9a3e630cf066.jpg",
                name: "YNT",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2Fx6tence-galaxy-logo.png&w=256&q=75",
                name: "x6tence Galaxy",
            },
            {
                image: "https://egamersworld.com/uploads/counterstrike/teams/xtc-logo.svg",
                name: "xTc",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2Frte-logo.png&w=256&q=75",
                name: "rTe",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2F2ez-logo.png&w=256&q=75",
                name: "2ez",
            },
        ],
    },
    {
        id: 2,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournamentBg,
        name: "ESL CS2 2v2 Duels #15",
        date: "Upcoming",
        mode: "2v2 Single elimination",
        shortMode: "2v2",
        access: true,
        participants: "4",
        prizes: "Steam cards",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "15",
        bracket_logic: "Follow by",
        map_pool: [
            {
                name: "aim_night",
                image: "https://steamuserimages-a.akamaihd.net/ugc/2313229111563764673/A211E8A60A3A029A4EF889F32621803B761F4DD3/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true",
            },
            {
                name: "aim_deagle",
                image: "https://steamuserimages-a.akamaihd.net/ugc/2208513443995425117/AAF2DAF3FEF356C2975485D928FEA3726D544E48/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true",
            },
            {
                name: "Aim_Killerzone",
                image: "https://steamuserimages-a.akamaihd.net/ugc/2258053764421381807/BE5F1A0F67E64E8448AA4C23F1107A8C47270F9C/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - $70 Steam card", "2 place - $30 Steam card"],
        },
        participants_users: [
            {
                image: "https://egamersworld.com/uploads/counterstrike/teams/5balls-logo.svg",
                name: "5 Balls",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2F9z-logo.png&w=256&q=75",
                name: "9z",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2Fabt-esports-logo.png&w=256&q=75",
                name: "ABT Esports",
            },
        ],
    },
    {
        id: 3,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournamentBg2,
        name: "ESL CS2 2v2 Grand Competitive",
        date: "Upcoming",
        mode: "2v2 Single elimination",
        shortMode: "2v2",
        access: true,
        participants: "4",
        prizes: "CS 2 Skins",
        bracket_type: "Single elimination",
        match_for_3rd_place: false,
        time_voting: "15",
        bracket_logic: "Follow by",
        map_pool: [
            {
                name: "Short Dust",
                image: "https://steamuserimages-a.akamaihd.net/ugc/767236278283793248/8B257630D89BC2032CE545BD4C19959DBBDEA5E3/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true",
            },
            {
                name: "de_vertigo",
                image: "https://cyber-sport.io/imgs/news_354/20220828/1661696846_db6185bf973ce0d34e39.jpg",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - AK 47 Ice Coaled (Minimal Wear)",
                "2 place - AK 47 Slate (Minimal Wear)",
                "3 place - AK 47 Redline (Field-Tested)",
            ],
        },
        participants_users: [
            {
                image: "https://egamersworld.com/uploads/counterstrike/teams/5balls-logo.svg",
                name: "5 Balls",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2F9z-logo.png&w=256&q=75",
                name: "9z",
            },
            {
                image: "https://rus.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fcounterstrike%2Fteams%2Fabt-esports-logo.png&w=256&q=75",
                name: "ABT Esports",
            },
        ],
    },
    {
        id: 4,
        game: "cs2",
        game_name: "CS 2",
        cover_image: lanBg,
        name: "ESL CS2 LAN Tournament",
        date: "Upcoming",
        mode: "2v2 Single elimination",
        shortMode: "2v2",
        access: false,
        participants: "3",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "15",
        bracket_logic: "Follow by",
        map_pool: [maps.nuke, maps.inferno, maps.mirage],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - $1 000", "2 place - $500", "3 place - $250"],
        },
        participants_users: [
            {
                image: timer,
                name: "ESL Academy",
            },
            {
                image: timer,
                name: "ESL Junior",
            },
            {
                image: timer,
                name: "ESL-E",
            },
        ],
    },
    {
        id: 5,
        game: "cs2",
        game_name: "CS 2",
        cover_image: competitive5v5,
        name: "ESL CS2 Inter Competitive 5v5",
        date: "Upcoming",
        mode: "5v5 Single elimination",
        shortMode: "5v5",
        access: true,
        participants: "32",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "60",
        bracket_logic: "Follow by",
        map_pool: [maps.mirage, maps.office, maps.anubis],
        prime: true,
        stand_in: "15",
        description: {
            prizes: [
                "MVP Player - $1 700",
                "1 place - $1 500",
                "2 place - $750",
                "3 place - $325",
            ],
        },
        participants_users: [],
    },
    {
        id: 6,
        game: "cs2",
        game_name: "CS 2",
        cover_image: zeroGravityBg,
        name: "ESL CS2 Zero Gravity FUN #12",
        date: "Upcoming",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: true,
        participants: "128",
        prizes: "CS 2 Skins",
        bracket_type: "Competitive",
        match_for_3rd_place: true,
        time_voting: "15",
        bracket_logic: "Follow by",
        map_pool: [maps.lunacy],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - M4A1-S Printstream (Field-Tested)",
                "2 place - Desert Eagle Printstream (Field-Tested)",
                "3 place - USP-S Whiteout (Minimal Wear)",
            ],
        },
        participants_users: [],
    },
    {
        id: 7,
        game: "cs2",
        game_name: "CS 2",
        cover_image: dangerZoneBg,
        name: "ESL CS2 Danger Zone Elimination #23",
        date: "Outdated",
        mode: "Danger Zone",
        shortMode: "FFA",
        access: false,
        participants: "32",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [maps.sirocco],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - $900", "2 place - $300", "3 place - $100"],
        },
        participants_users: [],
    },
    {
        id: 8,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_8,
        name: "ESL Challenger League Season 47 Asia Pacific",
        date: "Upcoming",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "32",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [maps.office, maps.ancient, maps.nuke, maps.mirage],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 9,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_9,
        name: "ESL Challenger League Season 47 Europe",
        date: "Upcoming",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "32",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [maps.office, maps.ancient, maps.nuke, maps.mirage],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 10,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_10,
        name: "ESL Challenger League Season 46 North America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "24",
        prizes: "$ USD",
        bracket_type: "Group Stage then Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Seeded",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "2",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 11,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_11,
        name: "ESL Challenger League Season 46 Asia Pacific",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "32",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: false,
        time_voting: "20",
        bracket_logic: "Random",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 12,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_16,
        name: "ESL Challenger DreamHack League Atlanta 2023",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "16",
        prizes: "$ USD",
        bracket_type: "Double elimination",
        match_for_3rd_place: true,
        time_voting: "25",
        bracket_logic: "Seeded",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "2",
        description: {
            prizes: [
                "1 place - $50 000",
                "2 place - $20 000",
                "3 place - $10 000",
                "4 place - $10 000",
                "5 place - $3 000",
                "6 place - $3 000",
                "7 place - $2 000",
                "8 place - $2 000",
            ],
        },
        participants_users: [],
    },
    {
        id: 13,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_16,
        name: "ESL CS2 Premier Showdown",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "20",
        prizes: "$ USD",
        bracket_type: "Round Robin then Single elimination",
        match_for_3rd_place: false,
        time_voting: "15",
        bracket_logic: "Seeded",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $12 500",
                "3 place - $7 500",
                "4 place - $7 500",
                "5 place - $5 000",
                "6 place - $5 000",
                "7 place - $5 000",
                "8 place - $5 000",
            ],
        },
        participants_users: [],
    },
    {
        id: 14,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_14,
        name: "ESL CS2 Arctic Invitational",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "30",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $17 000",
                "2 place - $7 500",
                "3 place - $7 500",
                "4 place - $7 500",
                "5 place - $1 500",
                "6 place - $1 500",
                "7 place - $900",
                "8 place - $900",
            ],
        },
        participants_users: [],
    },
    {
        id: 15,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_15,
        name: "ESL Challenger League Season 46 Europe",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.ancient, maps.office, maps.mirage, maps.nuke],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 16,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_16,
        name: "ESL Challenger DreamHack League Winter 2023",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.nuke, maps.mirage, maps.office, maps.ancient],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [],
        },
        participants_users: [
            "1 place - $50 000",
            "2 place - $20 000",
            "3 place - $10 000",
            "4 place - $10 000",
            "5 place - $3 000",
            "6 place - $3 000",
            "7 place - $2 000",
            "8 place - $2 000",
        ],
    },
    {
        id: 17,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_17,
        name: "ESL Challenger League Season 47 South America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.office, maps.ancient, maps.nuke, maps.mirage],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 18,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_18,
        name: "ESL Challenger League Katowice 2023",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "24",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.office, maps.nuke, maps.inferno],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $50 000",
                "2 place - $20 000",
                "3 place - $10 000",
                "4 place - $10 000",
                "5 place - $3 000",
                "6 place - $3 000",
                "7 place - $2 000",
                "8 place - $2 000",
            ],
        },
        participants_users: [],
    },
    {
        id: 19,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_19,
        name: "ESL Challenger League Season 45 Europe",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [
            maps.office,
            maps.ancient,
            maps.nuke,
            maps.inferno,
            maps.mirage,
        ],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 20,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_20,
        name: "ESL Challenger League Season 45 Asia Pacific",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [
            maps.office,
            maps.ancient,
            maps.nuke,
            maps.inferno,
            maps.mirage,
        ],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $8 000",
                "2 place - $5 500",
                "3 place - $2 800",
                "4 place - $1 500",
                "5 place - $900",
                "6 place - $900",
                "7 place - $450",
                "8 place - $450",
            ],
        },
        participants_users: [],
    },
    {
        id: 21,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_21,
        name: "ESL Challenger League Season 45 North America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [
            maps.office,
            maps.ancient,
            maps.nuke,
            maps.inferno,
            maps.mirage,
        ],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 22,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_22,
        name: "ESL Challenger DreamHack League Melbourne 2023",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.inferno, maps.office, maps.ancient],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $80 000",
                "2 place - $20 000",
                "3 place - $10 000",
                "4 place - $10 000",
                "5 place - $3 000",
                "6 place - $2 000",
                "7 place - $2 000",
                "8 place - $2 000",
            ],
        },
        participants_users: [],
    },
    {
        id: 23,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_23,
        name: "ESL Challenger League Season 44 North America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.inferno, maps.anubis, maps.overpass],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 24,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_24,
        name: "ESL Challenger League Season 44 Europe",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.inferno, maps.anubis, maps.overpass],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 25,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_25,
        name: "ESL Challenger League Season 44 Asia Pacific",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.inferno, maps.anubis, maps.overpass],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $8 000",
                "2 place - $5 500",
                "3 place - $2 800",
                "4 place - $1 500",
                "5 place - $900",
                "6 place - $900",
                "7 place - $450",
                "8 place - $450",
            ],
        },
        participants_users: [],
    },
    {
        id: 26,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_26,
        name: "ESL Challenger League Season 43 Europe",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "30",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.mirage, maps.overpass, maps.ancient, maps.inferno],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 27,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_27,
        name: "ESL Challenger League Season 43 North America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.mirage, maps.overpass, maps.ancient, maps.inferno],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 28,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_28,
        name: "ESL Challenger League Season 43 Asia Pacific",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [maps.mirage, maps.overpass, maps.ancient, maps.inferno],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $8 000",
                "2 place - $5 500",
                "3 place - $2 800",
                "4 place - $1 500",
                "5 place - $900",
                "6 place - $900",
                "7 place - $450",
                "8 place - $450",
            ],
        },
        participants_users: [],
    },
    {
        id: 29,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_29,
        name: "ESL Challenger League Season 42 North America",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [
            maps.inferno,
            maps.nuke,
            maps.ancient,
            maps.anubis,
            maps.overpass,
            maps.mirage,
        ],
        prime: true,
        stand_in: "1",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 30,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_30,
        name: "ESL Challenger League Season 42 Europe",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "30",
        bracket_logic: "Random",
        map_pool: [],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $20 000",
                "2 place - $11 500",
                "3 place - $7 000",
                "4 place - $4 000",
                "5 place - $2 500",
                "6 place - $2 500",
                "7 place - $1 250",
                "8 place - $1 250",
            ],
        },
        participants_users: [],
    },
    {
        id: 31,
        game: "cs2",
        game_name: "CS 2",
        cover_image: tournament_31,
        name: "ESL Challenger League Season 42 Asia Pacific",
        date: "Outdated",
        mode: "5v5 Competitive",
        shortMode: "5v5",
        access: false,
        participants: "64",
        prizes: "$ USD",
        bracket_type: "Group Stage then Double elimination",
        match_for_3rd_place: true,
        time_voting: "20",
        bracket_logic: "Seeded",
        map_pool: [
            maps.inferno,
            maps.nuke,
            maps.ancient,
            maps.anubis,
            maps.overpass,
            maps.mirage,
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $8 000",
                "2 place - $5 000",
                "3 place - $2 800",
                "4 place - $1 500",
                "5 place - $900",
                "6 place - $900",
                "7 place - $450",
                "8 place - $450",
            ],
        },
        participants_users: [],
    },
    {
        id: 1,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image:
            "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota2_social.jpg",
        name: "ESL Dota 2 Premium Duel SKY #4",
        date: "Upcoming",
        mode: "Default",
        shortMode: "Default",
        access: true,
        participants: "128",
        prizes: "Steam Cards",
        bracket_type: "-",
        match_for_3rd_place: false,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "All heroes",
                image: "https://clan.cloudflare.steamstatic.com/images/3703047/3728d0dc0f78d43a27b431f8b5607ee31ca0987d.png",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - 560 $ Steam card",
                "2 place - 360 $ Steam card",
            ],
        },
        participants_users: [
            {
                image: "https://i.pinimg.com/originals/fe/8a/66/fe8a665354b199d9983082aea5a34be0.png",
                name: "TuEsNino",
            },
        ],
    },
    {
        id: 2,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image:
            "https://img.ixbt.site/live/images/original/15/26/60/2023/04/21/9c6a5b89c9.png?w=877",
        name: "ESL Dota 2 February Tournament",
        date: "Upcoming",
        mode: "Default Single elimination",
        shortMode: "Default",
        access: true,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "Single elimination",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "All heroes",
                image: "https://clan.cloudflare.steamstatic.com/images/3703047/3728d0dc0f78d43a27b431f8b5607ee31ca0987d.png",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - $1 200", "2 place - $600", "3 place - $160"],
        },
        participants_users: [],
    },
    {
        id: 3,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image: pudgeMania,
        name: "ESL Dota 2 Pudge Mania",
        date: "Upcoming",
        mode: "Default Single elimination",
        shortMode: "Default",
        access: true,
        participants: "32",
        prizes: "Dota 2 Skins",
        bracket_type: "Single elimination",
        match_for_3rd_place: false,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "Pudge",
                image: "https://media-management-service.s3.amazonaws.com/media/images/e0451f8496162e44c45c04b9f741cb3f_large_cover.B7.original.jpg",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: ["1 place - Exalted Feast of Abscession"],
        },
        participants_users: [],
    },
    {
        id: 4,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image: dota_4,
        name: "RES Regional Series: SEA #1",
        date: "Started",
        mode: "Default",
        shortMode: "Default",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "All heroes",
                image: "https://clan.cloudflare.steamstatic.com/images/3703047/3728d0dc0f78d43a27b431f8b5607ee31ca0987d.png",
            },
        ],
        prime: true,
        stand_in: "0",
        description: {
            prizes: [
                "1 place - $25 000",
                "2 place - $15 000",
                "3 place - $5 000",
                "4 place - $5 000",
            ],
        },
        participants_users: [
            {
                image: Execration,
                name: "Execration",
            },
            {
                image: geekFam,
                name: "Geek Fam",
            },

            {
                image: TalonEs,
                name: "Talon Esports",
            },
            {
                image: MIPH,
                name: "Made in Philippines",
            },
            {
                image: BleedEs,
                name: "Bleed Esports",
            },
            {
                image: TeamTough,
                name: "Team Tough",
            },
            {
                image: IHC,
                name: "IHC Esports",
            },
            {
                image: timer,
                name: "The immortal",
            },
            {
                image: MAGI,
                name: "MAG.Indonesia",
            },
            {
                image: MAGN,
                name: "MAG.Nirvana",
            },
            {
                image: timer,
                name: "Private Goofy",
            },
            {
                image: timer,
                name: "Batam Pride",
            },
        ],
    },
    {
        id: 5,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image: dota_5,
        name: "New Year Cup 2024",
        date: "Started",
        mode: "Default",
        shortMode: "Default",
        access: false,
        participants: "12",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "All heroes",
                image: "https://clan.cloudflare.steamstatic.com/images/3703047/3728d0dc0f78d43a27b431f8b5607ee31ca0987d.png",
            },
        ],
        stand_in: "0",
        description: {
            prizes: ["Pool - $30 000"],
        },
        participants_users: [
            {
                image: geekFam,
                name: "Geek Fam",
            },
            {
                image: YG,
                name: "Yangon Galacticos",
            },
            {
                image: TT,
                name: "Team Trust",
            },
            {
                image: PoEs,
                name: "Polaris Esports",
            },
            {
                image: MIPH,
                name: "Made in Philippines",
            },
            {
                image: TeamFreedom,
                name: "Team Freedom (Southeast Asian)",
            },
            {
                image: MAGI,
                name: "MAG.Indonesia",
            },
            {
                image: MeParb,
                name: "Me Parb",
            },
            {
                image: MAGN,
                name: "MAG.Nirvana",
            },
            {
                image: TeamDarleng,
                name: "Team Darleng",
            },
            {
                image: Ardour,
                name: "Ardour",
            },
            {
                image: timer,
                name: "Private Goofy",
            },
        ],
    },
    {
        id: 6,
        game: "dota-2",
        game_name: "Dota 2",
        cover_image: dota_6,
        name: "ESL One Asia 2024",
        date: "Upcoming",
        mode: "Default",
        shortMode: "Default",
        access: true,
        participants: "-",
        prizes: "$ USD",
        bracket_type: "-",
        match_for_3rd_place: true,
        time_voting: "-",
        bracket_logic: "-",
        map_pool: [
            {
                name: "All heroes",
                image: "https://clan.cloudflare.steamstatic.com/images/3703047/3728d0dc0f78d43a27b431f8b5607ee31ca0987d.png",
            },
        ],
        stand_in: "0",
        description: {
            prizes: ["Pool - $1 000 000"],
        },
        participants_users: [],
    },
];
