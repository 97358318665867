import { useNavigate } from "react-router-dom";

import { paths } from "../../router/router";
import "../../styles/components/UI/Logo.scss";

type LogoProps = {
    isWhite?: boolean;
};

const Logo = ({ isWhite }: LogoProps) => {
    const navigate = useNavigate();

    return (
        <svg
            onClick={() => navigate(paths.main)}
            className="logo"
            xmlns="http://www.w3.org/2000/svg"
            width="111"
            height="35"
            viewBox="0 0 111 35"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110.36 30.9513V24.4271H97.4103V2.92499H90.0928V24.4275C90.0928 28.0304 92.9595 30.9513 96.4955 30.9513H110.36Z"
                fill={`${!isWhite ? "#000" : "#fff"}`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M79.1134 13.9657H72.4913V9.4488H86.1251V2.92499H73.433C68.8717 2.92499 65.1738 6.69284 65.1738 11.3405C65.1738 15.9882 68.8717 19.756 73.433 19.756H80.131V24.4271H66.4969V30.9513H79.1134C83.7183 30.9513 87.4504 27.1466 87.4485 22.4549C87.4465 17.766 83.7152 13.9657 79.1134 13.9657Z"
                fill={`${!isWhite ? "#000" : "#fff"}`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.2544 24.4275C40.2544 28.0304 43.1211 30.9513 46.657 30.9513H62.266V24.4271H47.5719V19.7564H61.3017V13.9661H47.5719V9.4492H62.266V2.92499H46.6524C43.1187 2.92499 40.2544 5.84395 40.2544 9.4441V24.4275Z"
                fill={`${!isWhite ? "#000" : "#fff"}`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7938 34.2231C7.51905 34.2231 0 26.5621 0 17.1117C0 7.66139 7.51905 0 16.7938 0C26.0686 0 33.5877 7.66139 33.5877 17.1117C33.5877 26.5621 26.0686 34.2231 16.7938 34.2231ZM6.2765 25.8553C5.71343 25.1571 5.21574 24.4018 4.79199 23.6001L17.5705 2.92505C20.6395 3.06739 23.4471 4.23109 25.6721 6.09261L6.2765 25.8553ZM10.4794 29.3599C9.68705 28.9317 8.94153 28.4274 8.25084 27.8552L27.635 8.10436C29.4512 10.3823 30.58 13.2505 30.7026 16.3832L10.4794 29.3599ZM13.7493 30.6141C22.5403 32.6073 29.1612 26.2722 30.4037 19.9275L13.7493 30.6141Z"
                fill={`${!isWhite ? "#000" : "#fff"}`}
            />
        </svg>
    );
};

export default Logo;
