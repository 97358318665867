const initialState = {
    game: null,
    tournament: null,
};

const gameReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_GAME":
            return { ...state, game: action.payload };
        case "SET_TOURNAMENT":
            return { ...state, tournament: action.payload };
        default:
            return state;
    }
};

export default gameReducer;
