import FaZe from "../sources/FaZe.png";

import G2 from "../sources/G2.png";

export const events = [
    {
        id: 1,
        game: "CS 2",
        name: "ESL Pro League Season 17",
        winner_image: FaZe,
        winner_name: "FaZe",
        date: "16 January - 3 April, 2023",
        tags: ["LAN"],
        location: "Malta",
        prize_pool: "$850 000",
        location_url:
            "https://www.google.com/maps/place/Malta/@35.9308668,14.5586362,9z/",
    },
    {
        id: 2,
        game: "CS 2",
        name: "IEM Katowice 2023",
        winner_image: G2,
        winner_name: "G2",
        date: "31 January - 13 February, 2023",
        tags: ["LAN"],
        location: "Poland, Katowice",
        prize_pool: "$1 000 000",
        location_url:
            "https://www.google.com/maps/place/Katowice,+Poland/@50.2837495,18.8038098,8.75z/",
    },
];
