import TournamentInfo from "../UI/TournamentInfo";

import { useSelector } from "react-redux";
import { games } from "../../data/games";
import "../../styles/components/TournamentsPage/TournamentsContent.scss";

const TournamentsContent = () => {
    const currentGame = useSelector((state: any) => state.gameReducer.game);

    const formatDescription = (array: string[]) => {
        return array
            .map((item: string) => item.trim())
            .filter((item: string | undefined) => item !== "");
    };

    const game: any = games.filter((item: any) => item.game == currentGame)[0];

    return (
        <div className="tournaments-content-container">
            <div className="tournaments-content">
                <TournamentInfo />

                <div className="tournaments-content-description">
                    <p className="fs-32">
                        {currentGame && game && game.game_name} Tournaments
                    </p>

                    <div className="tournaments-content-description-inner">
                        {game &&
                            currentGame &&
                            formatDescription(game.description.split("\n")).map(
                                (item: string, index: number) => (
                                    <p className="fs-16 desc" key={index}>
                                        {item}
                                    </p>
                                )
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentsContent;
