const initialState = {
    isLoggedIn: false,
    isAdminLoggedIn: false,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_IS_LOGGED_IN":
            return { ...state, isLoggedIn: action.payload };
        case "SET_ADMIN_IS_LOGGED_IN":
            return { ...state, isAdminLoggedIn: action.payload };
        default:
            return state;
    }
};

export default reducer;
