import Timer from "../sources/timer.png";

import Katowice2024Avatar from "../sources/IntelEMESL.png";

import EPLAvatar from "../sources/EPL.png";

import TeamVitaly from "../sources/Vitaly.png";

import PremierAvatar from "../sources/Premier.png";
import {
    shipsBlastPremier,
    shipsKatowice2024,
    tournamentsBlastPremier,
    tournamentsEPL,
    tournamentsKatowice,
} from "./sponsors_and_tournaments";

export const Katowice2024 = {
    title: "IEM Katowice 2024",
    shortDescription:
        "After success at BLAST Premier: Spring Groups 2024, Born to Win will take part in the biggest tournament of the winter – IEM Katowice 2024.",
    description:
        "After success at BLAST Premier: Spring Groups 2024, Born to Win will take part in the biggest tournament of the winter – IEM Katowice 2024.",
    avatar: Katowice2024Avatar,
    prizePool: "$1 000 000",
    date: "31 January - 12 February",
    participants: "24 Teams",
    location: "Poland, Katowice",
    tags: ["Lan"],
    tournaments: tournamentsKatowice,
    ships: shipsKatowice2024,
    winner: {
        avatar: Timer,
        name: "N/A",
        prize: "$N/A",
    },
};

export const EPL = {
    title: "European Pro League Season 16",
    shortDescription:
        "Season 16 of the European Pro League tournament with a prize pool of $20,000.",
    description:
        "Today, the NAVI Junior team will play its fourth match in the group stage of the European Pro League Season 16. The start of the best of 3 match is scheduled for 15:00 in Kyiv. Our team's opponent will be the Ninja Penguins team, which has played only one match so far and lost to Team Klee with a score of 1:2. It is worth noting that the NAVI Junior players played against this team quite recently as part of the DreamLeague Season 22 qualification. That meeting ended with a defeat of our boys with a score of 0:1. As for the composition of the enemy, it represents five highly rated e-sportsmen. The most famous member of the team can be called MiLAN, who has been performing on the professional stage for a long time. Connect to the start of the live broadcast and be sure to support NAVI Junior players! We are sure that the boys will show their best and achieve a winning result!",
    avatar: EPLAvatar,
    prizePool: "$20 000",
    date: "16 January - 10 February",
    participants: "12 Teams",
    location: "Europe and CIS",
    tags: ["Lan"],
    tournaments: tournamentsEPL,
    ships: [],
    winner: {
        avatar: Timer,
        name: "N/A",
        prize: "$10 000",
    },
};

export const BlastPremier = {
    title: "BLAST Premier Fall Final 2023",
    shortDescription:
        "BLAST Fall Final 2023 is the first tournament of the renewed Born to Win lineup.",
    description:
        "After yesterday's success, Born to Win got a chance to prove themselves in the playoffs. The quarterfinal match against Complexity will start at 19:30 and will be played in the best of 3 format. NAVI had almost no trouble handling NIP in the lower grid final. At the choice of the Overpass opponent, the team finished the half with a score of 10: 2, and despite a bad start in the second part, they coped with the difficulties and did not give a comeback (13: 11). Mirage NAVI looked much more confident, so they didn't leave the 'ninjas' any chance (13 : 6).",
    avatar: PremierAvatar,
    prizePool: "$425 000",
    date: "22 November - 27 November",
    participants: "8 Teams",
    location: "Denmark",
    tags: ["Lan"],
    tournaments: tournamentsBlastPremier,
    ships: shipsBlastPremier,
    winner: {
        avatar: TeamVitaly,
        name: "Team Vitaly",
        prize: "$200 000",
    },
};
