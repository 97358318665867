import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoggedIn } from "./redux/actions";
import Router from "./router/router";
import { Auth } from "./utils/auth";

const App = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const checkIfIsLoggedIn = async () => {
        const isLoggedIn = await Auth.checkIfUserLoggedIn();

        dispatch(setIsLoggedIn(await isLoggedIn));
    };

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 0);
        checkIfIsLoggedIn();
    }, [pathname]);

    return (
        <div className="App">
            <Router />
        </div>
    );
};

export default App;
