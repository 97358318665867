import { cs2Description, dota2Description } from "./descriptions";

export const games = [
    {
        game: "cs2",
        game_name: "CS 2",
        description: cs2Description,
    },
    {
        game: "dota-2",
        game_name: "Dota 2",
        description: dota2Description,
    },
];
